@font-face {
  font-family: Kalnia;
  src: url(assets/css/Fonts/Kalnia.ttf);
  font-weight: 700;
}
@font-face {
  font-family: Tilt Prism;
  src: url(assets/css/Fonts/TiltPrism.ttf);
  font-weight: 400;
}
@font-face {
  font-family: Pixelify Sans;
  src: url(assets/css/Fonts/PixelifySans.ttf);
  font-weight: 400;
}
:root {
  --text-color: #dadada;
  --tw-bg-opacity: 1;
  --bg-purple: rgb(11 2 26 / var(--tw-bg-opacity));
  --bg-purple-50: #0b021a80;
  --complementary: #aed2ff;
  --complementary-dark: #3b8ef4;
  --complementary-2: #9676ce;
  --pixelify: "Pixelify Sans", sans-serif;
  --Tilt: "Tilt Prism", sans-serif;
  --Kalnia: "Kalnia", serif;
  /* Infinite horizontal scroll animation  */
  --clr-neutral-100: hsl(0, 0%, 100%);
  --clr-primary-100: hsl(205, 15%, 58%);
  --clr-primary-400: hsl(215, 25%, 27%);
  --clr-primary-800: hsl(217, 33%, 17%);
  --clr-primary-900: hsl(218, 33%, 9%);
}

body {
  color: var(--text-color);
  height: 100vh;
  background-color: var(--bg-purple) !important;
}
#hero > div {
  opacity: 0;
}
#logoText {
  font-family: var(--Kalnia);
  font-size: 1.4rem;
  user-select: none;
}
.font-Kalnia {
  font-family: var(--Kalnia);
}
.navbar {
  color: var(--text-color);
}
#PixelHeroText {
  font-family: "Pixelify Sans", sans-serif;
  text-transform: uppercase;
  font-weight: 100;
  line-break: strict !important;
  display: inline-block;
}
#CodeHeroText {
  font-family: "Tilt Prism", sans-serif;
  line-break: strict !important;
  display: inline-block;
}
#heroText {
  font-family: serif;
}
/* .btn{
  border: thin solid var(--text-color);
  padding: 10px 2rem;
  border-radius: 45%;
} */
.btn {
  display: inline-block;
  text-decoration: none;
  position: relative;
  font-family: "Muli", sans-serif;
  font-weight: 300;
  line-height: 1;
  padding: 4px 0;
  color: var(--text-color);
  opacity: 0.75;
  transition: all 0.5s ease-in-out;
}
.btn svg {
  fill: transparent;
  stroke: var(--text-color);
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke-dasharray: 338;
  stroke-dashoffset: 338;
  stroke-linecap: round;
  position: absolute;
  top: 50%;
  left: 50%;
  width: calc(100% + 60px);
  opacity: 0;
  transform: translate(-50%, -50%);
  transition: stroke-dashoffset 0s 0.2s, opacity 0.2s;
  z-index: -1;
}

.btn:hover svg {
  stroke-dashoffset: 0;
  opacity: 1;
  transition: opacity 0s,
    stroke-dashoffset 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.btn:hover {
  color: white;
  opacity: 1;
}
.stackItem {
  background: #07435f;
  background-image: linear-gradient(to right top, #14243c, #11334e, #07435f);
  opacity: 0.75;
}
.stackItem:hover {
  background: #07435f;
  background-image: linear-gradient(to right top, #14243c, #11334e, #07435f);
  opacity: 1;
}
@media only screen and (max-width: 600px) {
  .stackItem {
    opacity: 1;
  }
  .btn svg {
    stroke-dashoffset: 0;
    opacity: 1;
    transition: opacity 0s,
      stroke-dashoffset 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  }
  .btn {
    color: white;
    opacity: 1;
  }
}
.link {
  border-bottom: thin dashed var(--complementary);
}
.SubHeadText {
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
}
.project_title {
  font-family: var(--Kalnia);
}
.TypedText {
  background-color: red;
}

.bg-purple {
  background-color: var(--bg-purple);
}
.footer-gradient {
  background: radial-gradient(
    128.5% 107.13% at 50% 2.92%,
    transparent 37.41%,
    #a033ee 78.65%,
    #fff 100%
  );
  position: relative;
  bottom: 0;
  z-index: 999 !important;
}
.black-gradient {
  background: linear-gradient(hsla(0, 0%, 100%, 0.05), hsla(0, 0%, 100%, 0.02));
  transition: all 1s ease-in-out;
}
.black-gradient:hover {
  background: linear-gradient(hsla(0, 0%, 100%, 0.05), rgba(79, 1, 103, 0.23));
}
pre {
  background-color: var(--text-color);
  color: var(--bg-purple);
  border: 1px solid var(--bg-purple);
  padding: 10px;
  margin: 10px 0px;
  overflow-x: auto;
  font-family: monospace;
}

/* Infinite horizontal scroll animation */
.tag-list {
  margin: 0;
  padding-inline: 0;
  list-style: none;
}

.tag-list li {
  padding: 1rem;
  /* background-color: var(--clr-primary-400); */
  background-color: #add8e6e6;
  border-radius: 0.5rem;
  box-shadow: 0 0.5rem 1rem -0.25rem rgba(173, 216, 230, 0.227);
}

.scroller__inner {
  padding-block: 1rem;
  display: flex;
  flex-wrap: wrap;
  gap: 1.75rem;
}

.scroller[data-animated="true"] {
  overflow: hidden;
  -webkit-mask: linear-gradient(
    90deg,
    transparent,
    white 20%,
    white 80%,
    transparent
  );
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
}

.scroller[data-animated="true"] .scroller__inner {
  width: max-content;
  flex-wrap: nowrap;
  animation: scroll var(--_animation-duration, 40s)
    var(--_animation-direction, forwards) linear infinite;
}

.scroller[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 20s;
}

.scroller[data-speed="mediam"] {
  --_animation-duration: 30s;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 60s;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

.WorkImg::after {
  content: "";
  width: 100%;
  height: 100%;
  background-color: #dadada;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  border-radius: 1.5rem;
}
